scroll-timeline {
    --scroll-y: 0;
  }
  
  .box-1, .box-3 {
    animation: fadeInLeft 3s ease-out;
    animation-timeline: var(--scroll-y);
  }
  .box-2 {
    animation: fadeInRight 3s ease-out;
    animation-timeline: var(--scroll-y);
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  