* {
  scrollbar-width: none;
}
html {
  box-sizing: content-box;
  overflow-x: hidden;
}
.App {

  background: linear-gradient(20deg, #d5a5e7, #6ed8aa);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 3s ease-in-out infinite;
  -moz-animation: AnimationName 3s ease-in-out infinite;
  animation: AnimationName 3s ease-in-out infinite;
  /* background-image: url('./components/icons/lib1.jpg'); */
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  opacity: 10px; 
  backdrop-filter: blur(10px);
  z-index: 0;
}
/* Overall div */
.App-header {
  position: sticky;
  top: 0;
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 15vh;
  width: 100vw;
}
.logo {
  /* height: 250px;
  width: 300px; */
}
.Nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Nav-bar div {
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}
.Nav-bar div:hover {
  color: black;
  background-color: rgb(147, 252, 147);
  transition: ease-in 0.7s;
}
.Nav-bar div a {
  color: white;
  text-decoration: none;
}
.Nav-bar div a:hover {
  color: black;
  background-color: rgb(147, 252, 147);
  transition: ease-in 0.7s;
}
.menuIcon {
  height: 45px;
}
/* Search component */
.searchForm {
  background-color: #282c34;
}
.searchInput, .searchButton{
  margin: 10px 2px;
  height: 45px;
  font-size: 20px;
  border-radius: 10px;
  border-style: groove;
}
.searchInput {
  width: 270px;
}
.searchButton {
  width: 100px;

}
.searchButton:hover {
  background-color: rgb(147, 252, 147);
  transition: ease-in 0.7s;
}
.search-viewer-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;

}
/* List component */
.list-div {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
}
.listRow {
  width: 85vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  
}
/* Card components */
.card-div {
  box-shadow: #282c34 1px 3px 3px;
  opacity: 5;
  transition: opacity 0.3s ease-in-out;
  /* height: 45vh;
  width: 300px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  border-radius: 10px;
  color: black;
}
.card-div.show {
  opacity: 1;

}
.card-content {
  margin: 2px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.download-button {
  width: 80px;
  height: 30px;
  font-weight: bolder;
  border-style: groove;
  border-radius: 10px;
}
.download-button:hover {
  color: white;
  background-color: #282c34;
  transition: all 1s;
  border-style: none;  
}
.book-destription-div {
  text-align: left;
  font-size: large;
  font-family: sans-serif;
  height: 190px;
}
.book-detail-div {
  display: block;
  text-align: left;
}

/* Upload component */
.form-div {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 75vh;
}
.uploadForm {
  box-shadow: #282c34 1px 3px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 600px;
  background: rgb(34,195,126);
background: radial-gradient(circle, rgba(34,195,126,1) 0%, rgba(225,235,132,1) 100%);
  border-radius: 10px;

}
.fileName, .bookName, .bookCategory, .UploaderName {
  font-size: larger;
  width: 350px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  border-style: none;
}
.bookDescription {
  font-size: larger;
  margin: 10px;
  max-width: 350px;
  max-height: 110px;
  min-width: 350px;
  min-height: 110px;
  border-radius: 5px;
  border-style: none;
}
.submitButton {
  width: 180px;
  height: 40px;
  font-weight: bolder;
  border-radius: 10px;
  border-style: none;

}
.submitButton:hover {
  color: white;
  background-color: #282c34;
  transition: all 1s;
  border-style: none;

}
.errorName {
}
.App-link {
  color: #61dafb;
}
/* Not found */
.Not-found-div {
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.not-found-header {
  color: crimson;
  font-size: 10rem;
  margin: 0px;
}
.Not-found-div p {
  font-size: 2rem;
  color: rgb(147, 252, 147)
}
/* Preloader */
.preloader-div {
  width: 100vw;
  height: 75vh;
}
.loading-header {
  color: rgb(147, 252, 147);
}
/* Footer */
footer {
  background-color: #282c34;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(5px + 1vmin);
  color: rgb(240, 239, 239);
  width: 100vw;
}
.footer-contact-div, .footer-about-div, .footer-navigation-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-contents, .about-contents, .navigation-contents {
  width: 250px;
  height: 250px;
}
.about-contents {
  text-align: justify;
}
.contact-contents a, .navigation-contents a {
  text-decoration: none;
  color: white;
}
.contact-contents a:hover, .navigation-contents a:hover {
  text-decoration: underline;
}
.icon {
  margin-left: 10px;
  background-color: white;
  width: 20px;
  height: 20px;
}
.icon:hover {
  background-color: rgb(147, 252, 147);
}